import React from 'react';
import { Grid } from "@mui/material";
import {  AutocompleteInput, ShowButton, SaveButton } from 'react-admin';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import SaveIcon from '@mui/icons-material/Save';

const OrderStatusAndPrintSection = ({ orderStatuses }) => {
  return (
    
    <Grid item sx={{ margin: 0.4, display: 'flex', flexDirection: 'column', alignContent: 'flex-end' }}>
      <AutocompleteInput
        variant="outlined"
        label="Tilauksen tila"
        optionText="translated"
        optionValue="name"
        source="status"
        choices={orderStatuses}
      />
      <SaveButton
        variant="contained"
        color="success"
        size="large"
        label="Tallenna muutokset"
        sx={{ marginTop: 1 }}
        icon={<SaveIcon />}
      />
      <ShowButton
        variant="outlined"
        color="info"
        size="large"
        label="Tulosta keräilylista"
        sx={{ marginTop: 1 }}
        icon={<LocalPrintshopIcon />}
      />
    </Grid>
  );
};

export default OrderStatusAndPrintSection;