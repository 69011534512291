import React from 'react';
import { Grid } from "@mui/material";
import CreateShipmentButton from './CreateShipmentButton';
import OrderStatusAndPrintSection from './OrderStatusAndPrintSection';
import orderStatuses from '../data/orderStatuses.json';

const OrderActions = ({ record }) => {
    return (
      <Grid container md={12} sm={12}>
        <Grid item md={8} sm={12}>
          <CreateShipmentButton />
        </Grid>
        <Grid item md={4} sm={12}>
          <OrderStatusAndPrintSection orderStatuses={orderStatuses} />
        </Grid>

      </Grid>
    );
};

export default OrderActions;