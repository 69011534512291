import React from "react";
import {
    ArrayField,
    Datagrid,
    DateField,
    ImageField,
    List,
    NumberField,
    SingleFieldList,
    TextField,
    FunctionField,
    Button
} from "react-admin";

import { Typography, Grid, Card, CardContent } from "@mui/material";

import stockStatuses from '../data/stockStatuses.json';

// Used for styling the row. Out of stock items should be highlighted
const postRowStyle = (record) => ({
    backgroundColor: 'inherit',
    border: record.published ? '1px solid white' : '1px dashed white',
    color: record.published  ? 'grey' : 'inherit', // Change text color for out-of-stock
    opacity: record.published ? 0.5 : 1, // Reduce opacity for out-of-stock
    padding: 2,
    margin: 2,
});


const NameHeader = () => (
    <Typography variant="h4">Kirjan nimi</Typography>
);

const AuthorHeader = () => (
    <Typography variant="h4">Tekijä</Typography>
);
const ShelfHeader = () => (
    <Typography variant="h4">Hylly</Typography>
);

const LayoutHeader = () => (
    <Typography variant="h4">Sidosasu</Typography>
);

const IsbnHeader = () => (
    <Typography variant="h4">ISBN</Typography>
);

const StatusHeader = () => (
    <Typography variant="h4">Varaston tila</Typography>
);

const PriceHeader = () => (
    <Typography variant="h4">Hinta</Typography>
);

const QuantityHeader = () => (
    <Typography variant="h4">Määrä</Typography>
);


const determineRowClick = (record) => {
    return record?.published ? false : 'edit';
};
export const BatchListView = (props) => {
    return(    
    <List 
    sort={{ field: 'id', order: 'DESC' }}
    queryOptions={{ onSettled: (data, error) => console.log(data, error) }}
   
    perPage={10}
    title="admin.kirjain.xyz :: Eräajo" 
    {...props}
    sx={{ 
        padding: 2, 
        spacing: 2, 
        marginTop: 2, 
        marginBottom: 2, 
        marginLeft: 2, 
        marginRight: 2, 
        border: "0.2px", 
        borderRadius: "10px", 
        boxShadow: "0px 0px 1px 0px #000000"
    }}
    >

        <Datagrid optimized 
        rowClick={determineRowClick}
        bulkActionButtons={false}
        rowSx={postRowStyle}>
            <Card sx={{backgroundColor:'white', border: 'inherit', padding: 1, margin: 1}}>
                <CardContent>
                <Grid 
                        container
                        spacing={1}
                        sx={{marginTop: 2, marginBottom: 1, marginLeft: 1, marginRight: 1}}
                        >
                    <Grid item>
                        <Typography variant="h4">Kirjan julkaisupäivä</Typography>
                        <DateField label="Julkaisupäivä" source="release_date" />
                    </Grid>
                    <Grid item>
                        <ArrayField
                            label={false}
                            source="images">
                            <SingleFieldList >
                                <ImageField loading="lazy" source="src" />
                            </SingleFieldList>
                        </ArrayField>   
                    </Grid>
                    <Grid 
                    container
                    spacing={1}>
                        <Grid item md={3}>
                            <FunctionField label="Julkaistu" render={
                                record => record.published ? 
                                <Button size="large" variant="contained" color="success">Julkaistu</Button> : 
                            <Button size="large" variant="contained" color="warning">Ei julkaistu</Button>
                                }/>
                            </Grid>
                        <Grid 
                        container
                        spacing={1.5}>
                            <Grid item md={3}>
                                <NameHeader/>
                                <TextField label="Nimi" source="name" />
                            </Grid>
                            <Grid item md={3}>
                                <AuthorHeader/>
                                <TextField label="Tekijä" source="author" />
                            </Grid>
                            <Grid item md={3}>
                                <ShelfHeader/>
                                <TextField label="Hylly" source="shelf"/>
                            </Grid>
                            <Grid item md={3}>
                                <LayoutHeader/>
                                <TextField label="Sidosasu" source="layout"/>
                            </Grid>
                            
                        </Grid>
                        <Grid container spacing={1.5}>
                        <Grid item md={3}>
                                <IsbnHeader/>
                                <TextField label="ISBN" defaultValue="" source="isbn"/>
                            </Grid>
                            <Grid item md={3}>
                                <StatusHeader />
                                <FunctionField label="Varaston tila" render={
                                    record => `${stockStatuses.find(status => status.name === record.stock_status).translated}`}/>
                            </Grid>
                            <Grid item md={3}>
                                <PriceHeader />
                                <NumberField
                                    label="Hinta"
                                    source="price"
                                    locales="fi-FI"
                                    options={{ style: 'currency', currency: 'EUR' }}/>
                            </Grid>
                            <Grid item md={3}>
                                <QuantityHeader />
                                <TextField label="Määrä" source="stock_quantity"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    </Grid>
                </CardContent>
               </Card> 
        </Datagrid>
    </List>
)
};
