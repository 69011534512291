import { ArrayField, ImageField, Datagrid, DateField, InfiniteList, NumberField, SingleFieldList, TextField } from 'react-admin';

export const BatchReportList = () => (
    <InfiniteList sort={{ field: 'release_date', order: 'DESC' }}>
        <Datagrid rowClick={false}>
        <TextField source="id" />
        <ArrayField
                            label={false}
                            source="images">
                            <SingleFieldList >
                                <ImageField 
                                loading="lazy"
                                source="src" 
                                />
                            </SingleFieldList>
                        </ArrayField>  

        <TextField label="Lisääjä" source="user" />
        <TextField label="kauppias" source="vendor" />
        <DateField label="Lisäyspvm." source="release_date" />
            
            <TextField label="Nimi" source="name" />
            <TextField label="Hinta" source="price" />
            <NumberField label="Määrä" source="stock_quantity" />
        </Datagrid>
    </InfiniteList>
);