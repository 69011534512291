import React, { useState } from "react";
import {
    ArrayField,
    Button,
    Datagrid,
    Edit,
    NumberField,
    SimpleForm,
    TextField,
    TextInput,
    BooleanField,
    DateField,
    FunctionField,
    ImageField,
    useRecordContext,
    useRedirect,
    TopToolbar,
    DeleteButton
} from "react-admin";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { Grid, Typography, IconButton, Box, Stack, Card, CardContent } from "@mui/material";
import VerifiedIcon from '@mui/icons-material/Verified';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import DrawSharpIcon from '@mui/icons-material/DrawSharp';
import DoneIcon from '@mui/icons-material/Done';
import vatCodes from '../data/vatCodes.json';
import OrderActions from "../components/OrderActions";

const vatCode = (record) => {
    const vendor = record?.vendorName;
    return vatCodes[vendor];
}

// Custom toolbar with just a back button
const CustomToolbar = () => {
    const redirect = useRedirect();
    
    return (
        <TopToolbar>
            <Button
                variant="outlined"
                color="info"
                size="large"
                label="Takaisin tilauksiin"
                onClick={() => redirect(-1)}
                startIcon={<ArrowBackIcon />}
            />
        </TopToolbar>
    );
};

const shippingMethod = (record) => {
    switch (record?.shipping_lines[0]?.method_id) {
        case 'local_pickup':
            return 'Nouto liikkeestä';
        case 'wcv_pro_vendor_shipping':
            return 'Postitus';
        default:
            return 'Postitus';
    }
}

// Responsive header components with appropriate sizing
const SectionHeader = ({ children, variant = "h4", mobileVariant = "h5" }) => {
    const isSmallScreen = window.innerWidth < 600;
    return (
        <Typography variant={isSmallScreen ? mobileVariant : variant} sx={{ mb: 1 }}>
            {children}
        </Typography>
    );
};

// Mobile-friendly line items display
const MobileLineItems = () => {
    const record = useRecordContext();
    if (!record || !record.line_items) return null;

    return (
        <Box sx={{ mt: 2 }}>
            {record.line_items.map((item, index) => (
                <Card key={index} sx={{ mb: 2, p: 1 }}>
                    <CardContent sx={{ p: 1, "&:last-child": { pb: 1 } }}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={4}>
                                {item.image && <img 
                                    src={item.image.src} 
                                    alt={item.name} 
                                    style={{ maxWidth: '100%', height: 'auto' }} 
                                />}
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="subtitle1">{item.name}</Typography>
                                <Typography variant="body2">ID: {item.product_id}</Typography>
                                <Typography variant="body2">Tekijä: {item.author}</Typography>
                                <Typography variant="body2">Sidosasu: {item.binding}</Typography>
                                <Typography variant="body2">Hylly: {item.shelf}</Typography>
                                <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                                    <Button 
                                        size='small' 
                                        variant="contained" 
                                        color={parseInt(item.quantity) > 1 ? 'info' : 'inherit'}
                                    >
                                        Määrä: {item.quantity}
                                        {parseInt(item.quantity) > 1 && 
                                            <NotificationImportantIcon sx={{ ml: 1, fontSize: 'small' }} />
                                        }
                                    </Button>
                                    <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
                                        {item.shipped ? 
                                            <VerifiedIcon color="success" /> : 
                                            <UnpublishedIcon color="error" />
                                        }
                                        <Typography variant="body2" sx={{ ml: 0.5 }}>
                                            {item.shipped ? "Toimitettu" : "Ei toimitettu"}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    Myyjä: {item.meta_data?.[0]?.display_value || ''}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
};

const AddressSection = ({ type }) => {
    const [isEditing, setIsEditing] = useState(false);
    const isSmallScreen = window.innerWidth < 600;
    
    // Use proper WooCommerce API field naming
    const prefix = type.toLowerCase();
    
    // Handle toggle without losing data
    const handleToggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <Grid item xs={12} sm={6} md={4} sx={{ mb: isSmallScreen ? 3 : 0 }}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <Typography variant={isSmallScreen ? "h5" : "h4"}>
                        {type === "Billing" ? "Laskutusosoite" : "Toimitusosoite"}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={handleToggleEdit}
                        size={isSmallScreen ? "medium" : "large"}
                    >
                        {isEditing ? 
                            <DoneIcon 
                                titleAccess="Muokkaus valmis"
                                sx={{border: 0.1, borderRadius: 4}}
                                color="success"
                            /> : 
                            <DrawSharpIcon
                                titleAccess="Muokkaa osoitetta"
                                sx={{border: 0.1, borderRadius: 1}}
                                color="error"
                            />
                        }
                    </IconButton>
                </Grid>
            </Grid>
            <Box sx={{ mt: 1.5 }}>
                {/* Name fields */}
                <Box sx={{ mb: 1 }}>
                    {isEditing ? (
                        <>
                            <TextInput
                                label="Etunimi"
                                source={`${prefix}.first_name`}
                                fullWidth
                                sx={{ mb: 1 }}
                            />
                            <TextInput
                                label="Sukunimi"
                                source={`${prefix}.last_name`}
                                fullWidth
                            />
                        </>
                    ) : (
                        <FunctionField
                            label="Nimi"
                            render={record => {
                                const data = record?.[prefix];
                                return data ? `${data.first_name || ''} ${data.last_name || ''}`.trim() : '';
                            }}
                        />
                    )}
                </Box>
                
                {/* Company field */}
                <Box sx={{ mb: 1 }}>
                    {isEditing ? (
                        <TextInput
                            label="Yritys"
                            source={`${prefix}.company`}
                            fullWidth
                        />
                    ) : (
                        <TextField
                            label="Yritys"
                            source={`${prefix}.company`}
                        />
                    )}
                </Box>
                
                {/* Address fields */}
                <Box sx={{ mb: 1 }}>
                    {isEditing ? (
                        <>
                            <TextInput
                                label="Osoite"
                                source={`${prefix}.address_1`}
                                fullWidth
                                sx={{ mb: 1 }}
                            />
                            <TextInput
                                label="Osoite 2"
                                source={`${prefix}.address_2`}
                                fullWidth
                            />
                        </>
                    ) : (
                        <FunctionField
                            label="Osoite"
                            render={record => {
                                const data = record?.[prefix];
                                if (!data) return '';
                                return [data.address_1, data.address_2].filter(Boolean).join(', ');
                            }}
                        />
                    )}
                </Box>
                
                {/* Other address fields */}
                {[
                    { label: "Postinumero", source: `${prefix}.postcode` },
                    { label: "Kaupunki", source: `${prefix}.city` },
                    { label: "Sähköposti", source: `${prefix}.email`, type: "email" },
                    { label: "Puhelin", source: `${prefix}.phone`, type: "tel" }
                ].map((field) => (
                    <Box key={field.source} sx={{ mb: 1 }}>
                        {isEditing ? (
                            <TextInput
                                label={field.label}
                                source={field.source}
                                type={field.type}
                                fullWidth
                            />
                        ) : (
                            <TextField
                                label={field.label}
                                source={field.source}
                            />
                        )}
                    </Box>
                ))}
            </Box>
        </Grid>
    );
};

export const OrderDetailView = (props) => {
    const [isBillingEditing, setIsBillingEditing] = useState(false);
    const [isShippingEditing, setIsShippingEditing] = useState(false);
    const isSmallScreen = window.innerWidth < 600;

    return (
        <Edit {...props}
        hasShow={false}
        hasCreate={false}
        >
            <SimpleForm toolbar={false}>
                <CustomToolbar />
                <Box className="printable">
                    {/* Order ID and Actions Section */}
                    <Grid container xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid item xs={12} sm={4} md={4}>
                            <SectionHeader variant="h2" mobileVariant="h4">Tilausnumero</SectionHeader>
                            <TextField source="id" />
                        </Grid>
                        {/* OrderActions will be on the same row on desktop */}
                        <Grid item xs={12} sm={8} md={8}>
                            <OrderActions record={props.record} />
                        </Grid>
                    </Grid>

                    {/* Order Items Section */}
                    <Box sx={{ mb: 3 }}>
                        <SectionHeader>Tilatut kirjat</SectionHeader>
                        {isSmallScreen ? (
                            <MobileLineItems />
                        ) : (
                            <ArrayField source="line_items">
                                <Datagrid bulkActionButtons={false}>
                                    <ImageField loading="lazy" source="image.src" label="Kuva"/>
                                    <TextField source="product_id" label="ID" />
                                    <TextField label="Nimi" source="name" />
                                    <TextField label="Tekijä" source="author"/>
                                    <TextField label="Sidosasu" source="binding"/>
                                    <TextField label="Hylly" source="shelf"/>
                                    <FunctionField
                                        label="Myyty määrä"
                                        render={record => {
                                            const quantity = record.quantity;
                                            const color = parseInt(quantity) > 1 ? 'info' : 'inherit';
                                            const attention = parseInt(quantity) > 1 ? 
                                                <NotificationImportantIcon sx={{ ml: 1, size: "small", color: "warning"}} /> : 
                                                '';
                                            return (
                                                <Button size='medium' variant="contained" color={color}>
                                                    {quantity}{attention}
                                                </Button>
                                            );
                                        }}
                                    />
                                    <TextField label="Myyjä" source="meta_data[0].display_value" />
                                    <BooleanField 
                                        label="Toimitettu" 
                                        TrueIcon={VerifiedIcon} 
                                        FalseIcon={UnpublishedIcon}
                                        source="shipped" 
                                    />
                                </Datagrid>
                            </ArrayField>
                        )}
                    </Box>

                    {/* Customer Note Section */}
                    <Box sx={{ mb: 3 }}>
                        <SectionHeader>Lisätiedot</SectionHeader>
                        <TextField source="customer_note" label="Tilauksen kommentti" />
                    </Box>

                    {/* Order Details Section */}
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Stack spacing={2}>
                                <Box>
                                    <SectionHeader>Tilauksen summa (€)</SectionHeader>
                                    <NumberField 
                                        locales="fi-FI"
                                        options={{ style: 'currency', currency: 'EUR' }} 
                                        source="total"
                                    />
                                </Box>
                                <Box>
                                    <SectionHeader>Toimituskulut (€)</SectionHeader>
                                    <NumberField 
                                        locales="fi-FI"
                                        options={{ style: 'currency', currency: 'EUR' }} 
                                        source="shipping_total" 
                                    />
                                </Box>
                                <Box>
                                    <SectionHeader>Myyjä</SectionHeader>
                                    <TextField source="vendorName"/>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Stack spacing={2}>
                                <Box>
                                    <SectionHeader>Tilauspvm</SectionHeader>
                                    <DateField source="date_created" />
                                </Box>
                                <Box>
                                    <SectionHeader>Maksutapa</SectionHeader>
                                    <TextField source="payment_method_title"/>
                                </Box>
                                <Box>
                                    <SectionHeader>Y-tunnus</SectionHeader>
                                    <FunctionField render={record => `${vatCode(record)}`} />
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Stack spacing={2}>
                                <Box>
                                    <SectionHeader>Toimitustapa</SectionHeader>
                                    <FunctionField
                                        render={(record) => {
                                            const orderShippingMethod = shippingMethod(record);
                                            const color = orderShippingMethod === 'Nouto liikkeestä' ? 'warning' : 'inherit';
                                            return (
                                                <Button 
                                                    size={isSmallScreen ? 'medium' : 'large'} 
                                                    variant="contained" 
                                                    color={color}
                                                >
                                                    {orderShippingMethod}
                                                </Button>
                                            )
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <SectionHeader>Verotus</SectionHeader>
                                    <Typography variant="body2">
                                        Marginaaliverotus, ei sisällä vähennettävää arvonlisäveroa.
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>

                    {/* Address Section */}
                    <Grid container spacing={2}>
                        <AddressSection
                            type="Billing"
                            isEditing={isBillingEditing}
                            onToggleEdit={() => setIsBillingEditing(!isBillingEditing)}
                        />
                        <AddressSection
                            type="Shipping"
                            isEditing={isShippingEditing}
                            onToggleEdit={() => setIsShippingEditing(!isShippingEditing)}
                        />
                    </Grid>

                    <DeleteButton
        variant="contained"
        color="error"
        size="large"
        label="Poista tilaus"
        sx={{ marginTop: 1 }}
        icon={<DeleteIcon />}
      />
                </Box>
                
            </SimpleForm>
        </Edit>
    );
};