import React, { useState } from 'react';
import { 
  Button, 
  NumberInput, 
  useRecordContext,
  useNotify,
  useDataProvider,
} from 'react-admin';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    Typography,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Grid
} from '@mui/material';
import { getShipment, getShipmentLocation } from '../api/smartShip';
import serviceCodes from '../config/serviceCodes.json';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { UserRoleContext } from '../UserRoleContext';
const enabledVendors = [
  'Kirjavehka',
  'Antikvariaatti Oranssi Planeetta',
  'Antikvaarinen kirjakauppa Lapponia',
  'Antikvariaatti Vihreä Planeetta',
  'Ilkan Kirja Ay'
];

const CreateShipmentButton = () => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [selectedProduct, setSelectedProduct] = useState('');
    const [shipmentPdfUrl, setshipmentPdfUrl] = useState('');
    const [shipmentLocations, setShipmentLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [isLocationDialogOpen, setIsLocationDialogOpen] = useState(false);
    const [parcelId, setparcelId] = useState('');
    const [parcels, setParcels] = useState(1); // Add state for parcels
    const record = useRecordContext();
    const { vendor } = React.useContext(UserRoleContext);
    const vendorName = vendor?.orderName[0];
    const usesShipment = enabledVendors.includes(vendorName) || false;
    
    // Quick select options from existing service codes
    const quickSelectOptions = serviceCodes.filter(code => 
        ['PO2103', 'PO2461'].includes(code.serviceCodePSS)
    );
    
    const handleQuickSelectChange = (serviceCodePSS) => {
        setSelectedProduct(serviceCodePSS);
    };

    const handleFindLocations = async () => {
      if (!record) return;
      try {
        const locations = await getShipmentLocation(record);
        setShipmentLocations(locations);
        setIsLocationDialogOpen(true);
      } catch (error) {
        console.error('Error finding locations:', error);
        notify(`Virhe haettaessa noutopisteitä ${error}`, 'error');
      }
    };
  
    const handleSelectLocation = (location) => {
        record.shipmentLocation = location;
        setSelectedLocation(location);
        setIsLocationDialogOpen(false);
    };
  
    const handleCreateShipment = async () => {
      if (!record || !selectedProduct) return;
      try {
        const selectedServiceCode = serviceCodes.find(
          code => code.serviceCodePSS === selectedProduct
        );
        
        const shipmentData = {
          ...record,
          service: selectedServiceCode.service,
          serviceCode: selectedProduct,
          location: selectedLocation,
          parcels,
        };
        
        const result = await getShipment(shipmentData, vendor);
        setshipmentPdfUrl(result.pdfs[0].href);
        setparcelId(result.parcels[0].parcelNo);
        notify('Lähetys luotu onnistuneesti', 'info');

        // Update the record with the shipment data
        const updatedRecord = {
          ...record,
          shipmentPdfUrl: result.pdfs[0].href,
          shipmentLocation: selectedLocation,
          service: selectedServiceCode.service,
          serviceCode: selectedProduct,
        };

        await dataProvider.update('orders', { id: record.id, data: updatedRecord });
        notify('Lähetystiedot tallennettu tilaukseen onnistuneesti', 'info');
      } catch (error) {
        notify(`Virhe luotaessa lähetystä: ${error}`, 'error');
        console.error('Error creating shipment:', error);
      }
    };
  
    if (!usesShipment) return null;
  
    return (
      <Grid container
        alignItems="flex-start" 
        sx={{ 
          border: 0.1, 
          borderRadius: 2,  
          borderColor: 'grey.500', 
          borderStyle: 'solid', 
          padding: 2
        }}
      >
        <Grid container >
          <Grid item xs={12} sm={5}>
            <FormControl fullWidth size="small">
              <RadioGroup
                aria-labelledby="quick-select-label"
                value={selectedProduct}
                name="quick-select-group"
                onChange={(e) => handleQuickSelectChange(e.target.value)}
              >
                {quickSelectOptions.map((option) => (
                  <FormControlLabel
                    key={option.serviceCodePSS}
                    value={option.serviceCodePSS}
                    control={<Radio />}
                    label={option.service}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={5}>
            <FormControl fullWidth size="small">
              <InputLabel id="service-code-label" sx={{marginTop: -1}}>Palvelukoodi</InputLabel>
              <Select
                labelId="service-code-label"
                id="service-code-select"
                value={selectedProduct}
                onChange={(e) => setSelectedProduct(e.target.value)}
                fullWidth
              >
                {serviceCodes.map((code) => (
                  <MenuItem key={code.serviceCodePSS} value={code.serviceCodePSS}>
                    {code.service}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={2}>
            <NumberInput
              size="small"
              source="parcels"
              fullWidth
              allowEmpty={false}
              min="1"
              defaultValue="1"
              label="Kollien määrä"
              value={parcels}
              onChange={(e) => setParcels(e.target.value)} // Update state on change
            />
          </Grid>
        </Grid>
        
        <Grid container>
    
        <Grid item xs={12}>
        <Button
          variant="outlined"
          color="info"
            size="medium"
          onClick={handleFindLocations}
          disabled={!record}
        >
          {selectedLocation 
            ? `Valittu: ${selectedLocation.name}` 
            : 'Valitse noutopiste'}
        </Button>
          </Grid>
    
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="info"
              size="medium"
              onClick={handleCreateShipment}
              disabled={!selectedProduct}
            >
              Luo lähetysetiketti
            </Button>
          </Grid>

            {/* Location selection dialog */}
      
      <Dialog 
        open={isLocationDialogOpen} 
        onClose={() => setIsLocationDialogOpen(false)}
      >
        <DialogTitle>Select Shipment Location</DialogTitle>
        <DialogContent>
          <List>
            {shipmentLocations.map((location) => (
              <ListItem
                key={location.id} 
                button 
                onClick={() => handleSelectLocation(location)}
              >
                <ListItemText 
                  primary={<Typography variant="h5" style={{ fontSize: '0.95rem' }}>
                  {location.name}
                </Typography>}
                  secondary={<Typography variant="h6" style={{ fontSize: '0.8rem' }}>
                    {location.address1}, {location.zipcode} {location.city}`
                    </Typography>} 
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>


          {shipmentPdfUrl && (
            <Grid item xs={12}>
              <Button
                component="a"
                href={shipmentPdfUrl}
                target="_blank"
                rel="noopener noreferrer"
                variant="contained"
                color="info"
                size='medium'
              >
               Lataa lähetysetiketti
              </Button>
            </Grid>
          )}
          {parcelId && (
          <Grid item xs={12}>
            <Button
              component="a"
              href={`https://www.posti.fi/fi/seuranta#/lahetys/${parcelId}?lang=fiparcelId`}
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
              color="info"
              size='small'
              sx={{border: 0}}
            >
              Lähetyksen seuranta
            </Button>
          </Grid>
        )}
        </Grid>
        
      </Grid>
    );
};

export default CreateShipmentButton;