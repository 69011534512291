export function orderListFilters(data, filter){
    // If filter is undefined, null, or an empty object, return the original data
    if (!filter || Object.keys(filter).length === 0) {
      return data;
    }
  
    // Check if the filter has any active nested values
    const hasActiveFilters = (filterObj) => {
      if (!filterObj || typeof filterObj !== 'object') return false;
      
      return Object.entries(filterObj).some(([k, v]) => {
        if (typeof v === 'object' && v !== null) {
          return hasActiveFilters(v);
        }
        return v !== '' && v !== null && v !== undefined;
      });
    };
    
    // If no active filters found, return the original data
    if (!hasActiveFilters(filter)) {
      return data;
    }
  
    return data.filter(item => {
      return matchesFilter(item, filter);
    });
  };
  
  // Helper function to recursively match an item against a filter
  const matchesFilter = (item, filter) => {
    // Check each property in the filter
    for (const key in filter) {
      const filterValue = filter[key];
      
      // If the filter value is an object, recursively check its properties
      if (filterValue && typeof filterValue === 'object') {
        // If the item doesn't have this property or it's not an object, it doesn't match
        if (!item[key] || typeof item[key] !== 'object') {
          return false;
        }
        
        // Recursively check if the nested object matches
        if (!matchesFilter(item[key], filterValue)) {
          return false;
        }
      }
      else if (key === 'line_items' && filterValue) {
        // Check if any line item name contains the search string
        const hasMatch = item.line_items && Array.isArray(item.line_items) && 
          item.line_items.some(lineItem => 
            lineItem.name && lineItem.name.toLowerCase().includes(filterValue.toLowerCase())
          );
        
        if (!hasMatch) {
          return false;
        }
      }
      // Special case for date ranges
      else if (key === 'date_created_gte') {
        if (!item.date_created || new Date(item.date_created) < new Date(filterValue)) {
          return false;
        }
      }
      else if (key === 'date_created_lte') {
        if (!item.date_created || new Date(item.date_created) > new Date(filterValue)) {
          return false;
        }
      }
      // For regular string properties
      else if (filterValue !== undefined && filterValue !== null && filterValue !== '') {
        const itemValue = item[key];
        
        // If item doesn't have the property, it doesn't match
        if (itemValue === undefined || itemValue === null) {
          return false;
        }
        
        // String comparison with includes for partial matches
        if (typeof itemValue === 'string' && typeof filterValue === 'string') {
          if (!itemValue.toLowerCase().includes(filterValue.toLowerCase())) {
            return false;
          }
        } 
        // Direct comparison for other types
        else if (itemValue.toString() !== filterValue.toString()) {
          return false;
        }
      }
    }
    
    // If we get here, all filter conditions were satisfied
    return true;
  };