import React from "react";
import { Filter, TextInput} from "react-admin";

const ProductFilter = (props) => {
    return (
        <Filter {...props} perPage={10} variant="outlined">
        <TextInput label="Haku" source="q" alwaysOn resettable="true"/>
        <TextInput sx={{width: 200}} label="Kirjan nimi" source="name" alwaysOn resettable/>
        <TextInput sx={{width: 200}} label="Tekijä" source="author" alwaysOn  resettable/>
        <TextInput sx={{width: 200}} label="Hylly" source="shelf"  alwaysOn resettable/>
        <TextInput sx={{width: 200}} label="Sidosasu" source="layout"  alwaysOn resettable/>
    </Filter>
    );
};

export default ProductFilter;