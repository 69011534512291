import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import {initializeApp} from "firebase/app";
import {firebaseConfig as config} from "../FIREBASE_CONFIG";
const app = initializeApp(config);
const storage = getStorage(app);

function getFormattedDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}${month}${day}`;
}

export async function storageUploader(file, imageName) {
// Create the file metadata
// @type {any}
const metadata = {
  contentType: 'image/*',
};
const folder = 'images/' + Date.now() + '/'
// Upload file and metadata to the object 'images/mountains.jpg'
const storageRef = ref(storage, folder + imageName);
const uploadedImage = await uploadBytesResumable(storageRef, file.rawFile, metadata).then((snapshot) => {
  console.log(snapshot.metadata)
  return snapshot.metadata;
});

return Promise.resolve(uploadedImage.fullPath);
}

export async function uploadPdfToStorage(buffer, fileName) {
  // Create the file metadata
  // @type {any}
  const metadata = {
    contentType: 'application/pdf',
  };
  const folder = 'pdfs/' + getFormattedDate() + '/'
  // Upload file and metadata to the object 
  const storageRef = ref(storage, folder + fileName);
  const uploadedPdf = await uploadBytesResumable(storageRef, buffer, metadata).then((snapshot) => {
    console.log(snapshot.metadata)
    return snapshot.metadata;
  });
  return Promise.resolve(uploadedPdf.fullPath);
}
