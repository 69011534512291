const axios = require('axios');
const smartShipConfig = require('../config/smartShip.json');
const smartShipTemplate = require('../data/smartShipTemplate.json');
const vendorAddresses = require('../config/vendorAddresses.json');
const { uploadPdfToStorage } = require('../helpers/storageUploader.js'); // Import the storageUploader function

const bucketUrl = 'https://storage.googleapis.com/prismatic-canto-249208.appspot.com/';


async function downloadPdf(url, basicAuth) {
    const fileName = url.split('/').pop() + '.pdf';
    const response = await axios.get(url, {
        responseType: 'arraybuffer',
        headers: {
            'Authorization': `Basic ${basicAuth}`,
            'Content-Type': 'application/pdf',
        },
    });
    const uploadedPdf = await uploadPdfToStorage(response.data, fileName); // Upload the PDF to Firebase Storage
    const uploadedUrl = bucketUrl + uploadedPdf;
    return uploadedUrl;
}

function parseOrderToPayload(order, vendorName) {
    const parcelCopies = order.parcels || 1;
    let payload = smartShipTemplate || {};
    payload.shipment.senderPartners = smartShipConfig[vendorName].senderPartners || undefined;
    payload.shipment.orderNo = order.id.toString();
    payload.shipment.sender = vendorAddresses[order.vendorName.toString()] || "";
    payload.shipment.senderReference = `${order.vendorName.toString()}: ${order.id.toString()}`;
    payload.shipment.receiver.name = order.shipping.first_name + ' ' + order.shipping.last_name || "";
    payload.shipment.receiver.company = order.shipping.company || "";
    payload.shipment.receiver.address1 = order.shipping.address_1 || "";
    payload.shipment.receiver.address2 = order.shipping.address_2 || "";
    payload.shipment.receiver.city = order.shipping.city || "";
    payload.shipment.receiver.state = order.shipping.state || "";
    payload.shipment.receiver.zipcode = order.shipping.postcode || "";
    payload.shipment.receiver.country = order.shipping.country || "";
    payload.shipment.receiver.mobile = order.shipping.phone || order.billing.phone || "";
    payload.shipment.receiver.email = order.shipping.email || order.billing.email || "";
    payload.shipment.parcels[0].copies = parcelCopies.toString() || "1";
    if (order?.location) {
        payload.shipment.agent.quickId = order.location.id || "";
    } else {
        delete payload.shipment.agent;
    }
    payload.shipment.service.id = order.serviceCode || "";
    const notifAddonData = {
        id: 'NOT',
        text3: payload.shipment.receiver.mobile,
        text4: payload.shipment.receiver.email
    };
    const optionsData = {
        id: 'ENOT',
        from: vendorAddresses[order.vendorName.toString()].email,
        to: payload.shipment.receiver.email
    };
    
    payload.shipment.options = [optionsData];
    payload.shipment.service.addOns = [notifAddonData]; 
    return payload;
}

async function getShipment(data, vendor) {
    const vendorName = vendor?.name[0];
    const basicAuth = smartShipConfig[vendorName].basicAuth;
    const payload = parseOrderToPayload(data, vendorName);
    console.log('Payload:', payload);
    const url = smartShipConfig.url + 'shipments/';
    try {
        const response = await axios.post(url, payload, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Basic ${basicAuth}`,
                'Content-Type': 'application/json'
            },
        });

        // Handle the response data
        console.log('Response data:', response.data[0]);
        const downloadedPdf = await downloadPdf(response.data[0].pdfs[0].href, basicAuth);
        console.log('Downloaded PDF:', downloadedPdf);
        response.data[0].pdfs = [{ href: downloadedPdf }];
        return response.data[0];
    } catch (error) {
        // Handle the error properly
        console.error('Error:', error.message);
        if (error.response) {
            console.error(error.response);
        }
        throw error.message;
    }
}

async function getAccessToken() {
    const consumerKey = smartShipConfig.locationApiConsumerKey;
    const consumerSecret = smartShipConfig.locationApiConsumerSecret;
    const endpoint = `${smartShipConfig.locationApiUrl}token`;
    const method = 'POST';
    const url = smartShipConfig.apiProxyUrl;
    
    try {
      const response = await axios.post(url, {
        endpoint: endpoint,
        method: method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `grant_type=client_credentials`,
        authType: 'basic',
        credentials: {
          key: consumerKey,
          secret: consumerSecret
        }        
      });
      return response.data.access_token;
    } catch (error) {
      console.error('Error obtaining access token:', error.message);
      return [error.response?.data?.message || error.response];
    }
  }

  async function getShipmentLocation(data, vendor) {
      let accessToken;
      try {
      accessToken = await getAccessToken(); // Obtain the access token
      } catch (error) {
        console.error('Error obtaining access token:', error);
        return [error.response?.data?.message || error.response];
      }
      try {
      const postCode = data.shipping.postcode;
      const address = data.shipping.address_1;
      const city = data.shipping.city;
      const countryCode = data.shipping.country;
      const url = `${smartShipConfig.locationApiUrl}location/v3/find-by-address?streetAddress=${address}&postcode=${postCode}&locality=${city}&countryCode=${countryCode}&limit=10`;
      
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
      });
      
      console.log('Response data:', response.data);
      
      // Parse the service points directly here
      const shipmentLocations = parseServicePoints(response.data);
      return shipmentLocations;
    } catch (error) {
      console.error('Error:', error.message);
      // Construct an error object that react-admin can handle
      return [error.response?.data?.message || error.response];
    }
  }
  
  // Helper function to parse service points
  function parseServicePoints(response) {
    if (!response || !response.servicePoints || !Array.isArray(response.servicePoints)) {
      return [];
    }
  
    return response.servicePoints.map(point => {
      // Get the Finnish address by default (or first available if Finnish not found)
      const addressInfo = point.addresses.find(addr => addr.languageCode === 'fi') || point.addresses[0];
      
      return {
        id: point.pupCode,
        name: addressInfo.publicName,
        address1: addressInfo.streetAddress,
        zipcode: addressInfo.postcode,
        city: addressInfo.city,
        coordinates: point.coordinates,
        // Additional useful information you might want to keep
        openingHours: point.availability.openingHours,
        capabilities: point.capabilities,
        isParcelLocker: point.parcelLocker,
        distanceInMeters: point.distanceInMeters
      };
    });
  }

module.exports = { getShipment, getShipmentLocation };