import html2pdf from 'html2pdf.js';
import orderStatuses from '../data/orderStatuses.json';

const exportToPDF = orders => {
    // Get the date 7 days ago
    const currentDate = new Date();
    const startTime = new Date(currentDate.setDate(currentDate.getDate() - 7));
    
    // Filter orders by status and date
    const filteredOrders = orders.filter(order => 
      new Date(order.date_created) >= startTime && order.status === 'processing'
      );
    // Filter and transform orders similar to CSV export
    const ordersToExport = filteredOrders.map(order => {
      const orderForExport = [];
      
      const lineItems = order.line_items;
      lineItems.forEach(lineItem => {
        const item = {
        id: order.id,
        date: new Date(order.date_created).toISOString().split('T')[0],
        status: orderStatuses.find(status => status.name === order.status).translated,
        name: lineItem.name,
        author: lineItem.author,
        shelf: lineItem.shelf,
        binding: lineItem.binding,
        quantity: lineItem.quantity,
      };
        orderForExport.push(item);
      });
      return orderForExport;
    });
  
    
  
    // Create HTML table with adjusted styles
    const tableHTML = `
      <div style="padding: 10px; font-family: Arial, sans-serif;">
        <h2 style="margin-bottom: 10px;">Keräilylista</h2>
        <table style="width: 100%; border-collapse: collapse; margin-top: 10px; font-size: 11px;">
          <thead>
            <tr style="background-color: #f3f4f6;">
              <th style="border: 1px solid #ddd; padding: 10px 8px; text-align: left; line-height: 1.4;">ID</th>
              <th style="border: 1px solid #ddd; padding: 10px 8px; text-align: left; line-height: 1.4;">Tilauspvm.</th>
              <th style="border: 1px solid #ddd; padding: 10px 8px; text-align: left; line-height: 1.4;">Tilayksen tila</th>
              <th style="border: 1px solid #ddd; padding: 10px 8px; text-align: left; line-height: 1.4;">Nimi</th>
              <th style="border: 1px solid #ddd; padding: 10px 8px; text-align: left; line-height: 1.4;">Tekijä</th>
              <th style="border: 1px solid #ddd; padding: 10px 8px; text-align: left; line-height: 1.4;">Hylly</th>
              <th style="border: 1px solid #ddd; padding: 10px 8px; text-align: left; line-height: 1.4;">Sidosasu</th>
              <th style="border: 1px solid #ddd; padding: 10px 8px; text-align: left; line-height: 1.4;">Määrä</th>
            </tr>
          </thead>
          <tbody>
            ${ordersToExport.map(order => 
                order.map(item =>`
              <tr>
                <td style="border: 1px solid #ddd; padding: 10px 8px; line-height: 1.4;">${item.id}</td>
                <td style="border: 1px solid #ddd; padding: 10px 8px; line-height: 1.4;">${item.date}</td>
                <td style="border: 1px solid #ddd; padding: 10px 8px; line-height: 1.4;">${item.status}</td>
                <td style="border: 1px solid #ddd; padding: 10px 8px; line-height: 1.4;">${item.name}</td>
                <td style="border: 1px solid #ddd; padding: 10px 8px; line-height: 1.4;">${item.author}</td>
                <td style="border: 1px solid #ddd; padding: 10px 8px; line-height: 1.4;">${item.shelf}</td>
                <td style="border: 1px solid #ddd; padding: 10px 8px; line-height: 1.4;">${item.binding}</td>
                <td style="border: 1px solid #ddd; padding: 10px 8px; line-height: 1.4;">${item.quantity}</td>
              </tr>
            `).join('')
            ).join('')}
          </tbody>
        </table>
      </div>
    `;
  
    // Create a temporary container for the HTML
    const element = document.createElement('div');
    element.innerHTML = tableHTML;
  
    // Configure PDF options
    const opt = {
      margin: [2, 1, 1, 1], // top, right, bottom, left margins in cm
      filename: `Keräilylista-${currentDate.toISOString().split('T')[0]}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { 
        unit: 'cm', 
        format: 'a4', 
        orientation: 'portrait'
      }
    };
  
    // Generate PDF
    html2pdf().set(opt).from(element).save();
  };
  
  export default exportToPDF;